import * as React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Button as MUIButton,
  Box,
} from "@mui/material";
import {
  Button,
  useGetList,
  Loading,
  useDataProvider,
  useGetOne,
  useRefresh,
} from "react-admin";
import { Desitions } from "./Desitions";
import { useNavigate, useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import RestoreIcon from "@mui/icons-material/Restore";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import MoreHorizIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PomView from "./PomView";
import NextPomView from "./NextPomView";
import { Countries as countries } from "./Countries";
import { MensWc, WomensWc } from "./MensWc";
import Parse from "parse";
export const Run = () => {
  const navigate = useNavigate();
  const user = Parse.User.current();
  const { platform } = useParams();
  const [timeTrigger, set_timeTrigger] = React.useState(0);
  const [round, setRound] = React.useState("1");
  const [current, setCurrent] = React.useState(null);
  const [startAction, setstartAction] = React.useState(false);
  const [ressetAction, setressetAction] = React.useState(false);
  const [result, setResult] = React.useState(null);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const {
    data: competition,
    isLoadingComp,
    errorComp,
  } = useGetOne("competitions", {
    id: localStorage.getItem("currentCompetition"),
  });

  const {
    data: lifters,
    total,
    isLoading,
    error,
  } = useGetList("lifters", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      platform,
    },
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "fullname", order: "ASC" },
  });
  const [refreshedLifters, setRefreshedLifters] = React.useState(
    lifters || null,
  );
  const {
    data: record,
    isLoading2,
    error2,
  } = useGetOne("platforms", { id: platform });
  const [refreshedPlatform, setRefreshedPlatform] = React.useState(record);
  // const platformRound = React.useMemo(() => {
  //     if(record && record.round)
  //       return(record.round)

  //       return 1
  // }, [record])

  const livequery = async () => {
    // const self = this;
    if (!refreshedPlatform && platform) {
      const { data: recordT } = await dataProvider.getOne("platforms", {
        id: platform,
      });
      setRefreshedPlatform(recordT);
    }

    const query = new Parse.Query("platforms");
    query.equalTo("objectId", platform);
    const subscription = await query.subscribe();
    subscription.on("update", async (object) => {
      console.log("Entro a update de la primera subscripción");
      console.log(object.toJSON());
      setRound(object.get("round"));
      setRefreshedPlatform(object.toJSON());
    });

    const queryLifters = new Parse.Query("lifters");
    queryLifters.equalTo("platform", platform);
    const subscriptionLifers = await queryLifters.subscribe();
    subscriptionLifers.on("update", async (object) => {
      //Obtengo al current
      const { data: liftersT } = await dataProvider.getList("lifters", {
        filter: {
          competition: localStorage.getItem("currentCompetition")
            ? localStorage.getItem("currentCompetition")
            : null,
          platform,
        },
        pagination: { page: 1, perPage: 10000 },
        sort: { field: "fullname", order: "ASC" },
      });
      setRefreshedLifters(liftersT);
    });
  };

  const getCurrent = async () => {
    const { data: recordT } = await dataProvider.getOne("platforms", {
      id: platform,
    });
    const { data: liftersT } = await dataProvider.getList("lifters", {
      filter: {
        competition: localStorage.getItem("currentCompetition")
          ? localStorage.getItem("currentCompetition")
          : null,
        platform,
      },
      pagination: { page: 1, perPage: 10000 },
      sort: { field: "fullname", order: "ASC" },
    });
    console.log(liftersT);
    const ordered = liftersT.sort((a, b) => {
      if (a.first_attempt === b.first_attempt) {
        return parseInt(a.lot_number) > parseInt(b.lot_number) ? -1 : 1;
      } else {
        return a.first_attempt < b.first_attempt ? -1 : 1;
      }
    });

    setCurrent(ordered[0]);
    dataProvider.update("platforms", {
      id: platform,
      data: { current: ordered[0] },
    });

    // if(!recordT.current) {
    //   //Se escoge al primero si estoy iniciando
    //   setCurrent(ordered[0])
    //   dataProvider.update('platforms', { id: platform, data: { current: ordered[0] } });
    // } else {
    //   setCurrent(recordT.current)
    // }
  };

  React.useEffect(() => {
    if (platform) {
      getCurrent();
      livequery();
    }
  }, [platform]);

  React.useEffect(() => {
    if (!isLoading && lifters.length && !refreshedLifters)
      setRefreshedLifters(lifters);
  }, [isLoading]);

  // React.useEffect(() => {
  //   // alert(record)
  //   if(!isLoading2 && record && !refreshedPlatform)
  //       setRefreshedPlatform(record)
  // }, [isLoading2, error2, record])

  React.useEffect(() => {
    if (refreshedPlatform) setRound(refreshedPlatform.round);
  }, [refreshedPlatform]);

  const goodLift1 = async (valido, lifter) => {
    set_timeTrigger((timeTrigger) => timeTrigger + 1);
    const { data: record } = await dataProvider.getOne("platforms", {
      id: platform,
    });
    const { data: lifters } = await dataProvider.getList("lifters", {
      filter: {
        competition: localStorage.getItem("currentCompetition")
          ? localStorage.getItem("currentCompetition")
          : null,
        platform,
      },
      pagination: { page: 1, perPage: 10000 },
      sort: { field: "fullname", order: "ASC" },
    });
    console.log(lifters);
    let resutls = record.first_attempt_results || {};
    resutls[lifter] = valido;
    dataProvider.update("platforms", {
      id: platform,
      data: { first_attempt_results: resutls, hr: [], rr: [], lr: [] },
    });

    const ordered = lifters.sort((a, b) => {
      if (a.first_attempt === b.first_attempt) {
        return parseInt(b.lot_number) < parseInt(a.lot_number) ? -1 : 1;
      } else {
        return a.first_attempt < b.first_attempt ? -1 : 1;
      }
    });

    const myIndex = ordered.findIndex((item) => item.id === lifter);
    const next =
      parseInt(myIndex + 1) === ordered.length ? 0 : parseInt(myIndex + 1);
    console.log(next);
    console.log(ordered[next]);
    if (next > 0) {
      setCurrent(ordered[next]);
      dataProvider.update("platforms", {
        id: platform,
        data: { current: ordered[next] },
      });
    } else {
      console.log(lifters.filter((item) => item.second_attempt));
      const orderedNextRound = lifters
        .filter((item) => item.second_attempt)
        .sort((a, b) => {
          if (a.second_attempt === b.second_attempt) {
            return parseInt(b.lot_number) < parseInt(a.lot_number) ? -1 : 1;
          } else {
            return a.second_attempt < b.second_attempt ? -1 : 1;
          }
        });
      setCurrent(orderedNextRound[0]);
      dataProvider.update("platforms", {
        id: platform,
        data: { current: orderedNextRound[0], round: "2" },
      });
    }
  };

  const goodLift2 = async (valido, lifter) => {
    set_timeTrigger((timeTrigger) => timeTrigger + 1);
    const { data: record } = await dataProvider.getOne("platforms", {
      id: platform,
    });
    const { data: lifters } = await dataProvider.getList("lifters", {
      filter: {
        competition: localStorage.getItem("currentCompetition")
          ? localStorage.getItem("currentCompetition")
          : null,
        platform,
      },
      pagination: { page: 1, perPage: 10000 },
      sort: { field: "fullname", order: "ASC" },
    });
    let resutls = record.second_attempt_results || {};
    resutls[lifter] = valido;
    dataProvider.update("platforms", {
      id: platform,
      data: { second_attempt_results: resutls, hr: [], rr: [], lr: [] },
    });

    const ordered = lifters.sort((a, b) => {
      if (a.second_attempt === b.second_attempt) {
        return parseInt(b.lot_number) < parseInt(a.lot_number) ? -1 : 1;
      } else {
        return a.second_attempt < b.second_attempt ? -1 : 1;
      }
    });

    const myIndex = ordered.findIndex((item) => item.id === lifter);
    const next =
      parseInt(myIndex + 1) === ordered.length ? 0 : parseInt(myIndex + 1);

    if (next > 0) {
      setCurrent(ordered[next]);
      dataProvider.update("platforms", {
        id: platform,
        data: { current: ordered[next] },
      });
    } else {
      const orderedNextRound = lifters
        .filter((item) => item.third_attempt)
        .sort((a, b) => {
          if (a.third_attempt === b.third_attempt) {
            return b.lot_number < a.lot_number ? -1 : 1;
          } else {
            return a.third_attempt < b.third_attempt ? -1 : 1;
          }
        });
      setCurrent(orderedNextRound[0]);
      dataProvider.update("platforms", {
        id: platform,
        data: { current: orderedNextRound[0], round: "3" },
      });
    }
  };

  const goodLift3 = async (valido, lifter) => {
    set_timeTrigger((timeTrigger) => timeTrigger + 1);
    const { data: record } = await dataProvider.getOne("platforms", {
      id: platform,
    });
    const { data: lifters } = await dataProvider.getList("lifters", {
      filter: {
        competition: localStorage.getItem("currentCompetition")
          ? localStorage.getItem("currentCompetition")
          : null,
        platform,
      },
      pagination: { page: 1, perPage: 10000 },
      sort: { field: "fullname", order: "ASC" },
    });
    let resutls = record.third_attempt_results || {};
    resutls[lifter] = valido;
    dataProvider.update("platforms", {
      id: platform,
      data: { third_attempt_results: resutls, hr: [], rr: [], lr: [] },
    });

    const ordered = lifters.sort((a, b) => {
      if (a.third_attempt === b.third_attempt) {
        return parseInt(b.lot_number) < parseInt(a.lot_number) ? -1 : 1;
      } else {
        return a.third_attempt < b.third_attempt ? -1 : 1;
      }
    });

    const myIndex = ordered.findIndex((item) => item.id === lifter);
    const next =
      parseInt(myIndex + 1) === ordered.length ? 0 : parseInt(myIndex + 1);

    if (next > 0) {
      setCurrent(ordered[next]);
      dataProvider.update("platforms", {
        id: platform,
        data: { current: ordered[next] },
      });
    } else {
      //Terminé la competencia
      // alert("terminé")
      setCurrent(null);
      dataProvider.update("platforms", {
        id: platform,
        data: { current: {}, finished: true },
      });
    }
  };

  const changeFirst = (e) => {
    console.log(e.target.id);
    console.log(e.target.value);
    const myId = e.target.id.split("-")[1];
    dataProvider
      .update("lifters", {
        id: myId,
        data: { first_attempt: parseInt(e.target.value) },
      })
      .then((results) => console.log(results));
  };

  const changeSecond = (e) => {
    const myId = e.target.id.split("-")[1];
    dataProvider
      .update("lifters", {
        id: myId,
        data: { second_attempt: parseInt(e.target.value) },
      })
      .then((results) => console.log(results));
  };

  const changeThird = (e) => {
    const myId = e.target.id.split("-")[1];
    dataProvider
      .update("lifters", {
        id: myId,
        data: { third_attempt: parseInt(e.target.value) },
      })
      .then((results) => console.log(results));
  };

  const changeRack = (e) => {
    const myId = e.target.id.split("-")[1];
    dataProvider
      .update("lifters", {
        id: myId,
        data: { rack_height: parseInt(e.target.value) },
      })
      .then((results) => console.log(results));
  };

  const clearResult = async (round, lifter) => {
    // alert(lifter)
    const { data: record } = await dataProvider.getOne("platforms", {
      id: platform,
    });
    if (round === 1) {
      let resutls = record.first_attempt_results || {};
      delete resutls[lifter];
      await dataProvider.update("platforms", {
        id: platform,
        data: { first_attempt_results: resutls },
      });
    }

    if (round === 2) {
      let resutls = record.second_attempt_results || {};
      delete resutls[lifter];
      await dataProvider.update("platforms", {
        id: platform,
        data: { second_attempt_results: resutls },
      });
    }
    if (round === 3) {
      let resutls = record.third_attempt_results || {};
      delete resutls[lifter];
      await dataProvider.update("platforms", {
        id: platform,
        data: { third_attempt_results: resutls },
      });
    }

    refresh();
  };

  const setRecord = async (recordName, round, lifter) => {
    alert(`Saving as ${recordName} record`);
    const { data: record } = await dataProvider.getOne("platforms", {
      id: platform,
    });
    if (round === 1) {
      let resutls = record.first_attempt_records || {};
      resutls[lifter] = resutls[lifter]
        ? { ...resutls[lifter], [recordName]: true }
        : { [recordName]: true };
      await dataProvider.update("platforms", {
        id: platform,
        data: { first_attempt_records: resutls },
      });
    }
    if (round === 2) {
      let resutls = record.second_attempt_records || {};
      resutls[lifter] = resutls[lifter]
        ? { ...resutls[lifter], [recordName]: true }
        : { [recordName]: true };
      await dataProvider.update("platforms", {
        id: platform,
        data: { second_attempt_records: resutls },
      });
    }
    if (round === 3) {
      let resutls = record.third_attempt_records || {};
      resutls[lifter] = resutls[lifter]
        ? { ...resutls[lifter], [recordName]: true }
        : { [recordName]: true };
      await dataProvider.update("platforms", {
        id: platform,
        data: { third_attempt_records: resutls },
      });
    }
    refresh();
  };

  const changeResult = async (round, lifter, desision) => {
    // alert(lifter)
    const { data: record } = await dataProvider.getOne("platforms", {
      id: platform,
    });
    if (round === 1) {
      let resutls = record.first_attempt_results || {};
      resutls[lifter] = desision;
      await dataProvider.update("platforms", {
        id: platform,
        data: { first_attempt_results: resutls },
      });
    }
    if (round === 2) {
      let resutls = record.second_attempt_results || {};
      resutls[lifter] = desision;
      await dataProvider.update("platforms", {
        id: platform,
        data: { second_attempt_results: resutls },
      });
    }
    if (round === 3) {
      let resutls = record.third_attempt_results || {};
      resutls[lifter] = desision;
      await dataProvider.update("platforms", {
        id: platform,
        data: { third_attempt_results: resutls },
      });
    }

    refresh();
  };

  const setCurrentAttemp = async (round, lifter) => {
    // alert(lifter)
    const { data: record } = await dataProvider.getOne("platforms", {
      id: platform,
    });
    await dataProvider.update("platforms", {
      id: platform,
      data: { current: lifter, round: round.toString() },
    });
    setCurrent(lifter);
    refresh();
  };

  const { data: teams } = useGetList("teams", {
    filter: { competition: localStorage.getItem("currentCompetition") },
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "name", order: "ASC" },
  });

  if (isLoading || isLoading2 || !refreshedPlatform || !record) {
    return <Loading />;
  }
  if (error || error2) {
    return <p>ERROR</p>;
  }

  if (record)
    return (
      <Card>
        {/*<CardHeader title={`Platform ${record ? record.name : ''}`} />*/}
        <CardContent>
          <Grid className="sticky" container spacing={2}>
            <Grid item xs={4}>
              <h2
                style={{ margin: 0 }}
              >{`Platform ${record ? record.name : ""}`}</h2>
              <MUIButton variant="contained" onClick={() => getCurrent()}>
                Set 1st Athlete{" "}
              </MUIButton>
            </Grid>
            <Grid item xs={4}>
              <PomView
                trigger={timeTrigger}
                startAction={startAction}
                ressetAction={ressetAction}
                seconds={
                  refreshedPlatform && refreshedPlatform.seconds
                    ? refreshedPlatform.seconds
                    : null
                }
                onResset={async (seconds) =>
                  dataProvider.update("platforms", {
                    id: platform,
                    data: { timer: seconds },
                  })
                }
                onStart={async (startStop) =>
                  dataProvider.update("platforms", {
                    id: platform,
                    data: { start: startStop },
                  })
                }
              />
              <NextPomView />
            </Grid>
            <Grid item xs={4}>
              {current ? current.fullname : ""}
              {` `}
              Round: {round}
              {/*refreshedPlatform.finished ? <MUIButton>Display podium</MUIButton> : null*/}
              {/*{refreshedPlatform && <div spacing={2}>
              <>CR : {refreshedPlatform.hr && refreshedPlatform.hr.map(desision => (
                <span
                  style={{
                    backgroundColor: desision === "bp" ? "turquoise" : 
                                      desision === "ds" ? "blue" :
                                        desision === "ss" ? "orange" : 
                                        desision === "ps" ? "purple" : "black",
                    padding: 5,marginRight: 5, color: 'white', fontWeight: 'bold'}}>
                {desision}
                </span>
              ))}<br/>
              RR: {refreshedPlatform.rr && refreshedPlatform.rr.map(desision => (
                <span
                  style={{
                    backgroundColor: desision === "bp" ? "turquoise" : 
                                      desision === "ds" ? "blue" :
                                        desision === "ss" ? "orange" : 
                                        desision === "ps" ? "purple" : "black",
                    padding: 5,marginRight: 5, color: 'white', fontWeight: 'bold'}}>
                {desision}
                </span>
              ))}<br/>
              LR: {refreshedPlatform.lr && refreshedPlatform.lr.map(desision => (
                <span
                  style={{
                    backgroundColor: desision === "bp" ? "turquoise" : 
                                      desision === "ds" ? "blue" :
                                        desision === "ss" ? "orange" : 
                                        desision === "ps" ? "purple" : "black",
                    padding: 5,marginRight: 5, color: 'white', fontWeight: 'bold'}}>
                {desision}
                </span>
              ))}</>
            </div>}*/}
              <Desitions platform={platform} />
            </Grid>
          </Grid>
          <TableContainer style={{ marginTop: 110 }} component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ border: "solid", borderWidth: 1 }}>
                    Lot No.
                  </TableCell>
                  <TableCell style={{ border: "solid", borderWidth: 1 }}>
                    Name
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Flag
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Country/Team
                  </TableCell>
                  <TableCell style={{ border: "solid", borderWidth: 1 }}>
                    BWC
                  </TableCell>
                  <TableCell style={{ border: "solid", borderWidth: 1 }}>
                    BW
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Round 1
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Round 2&nbsp;
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Round 3&nbsp;
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Rack Height
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Best
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Total
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    pDOTS
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Rank
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {refreshedLifters &&
                  refreshedLifters
                    .sort((a, b) => {
                      if (round === "1")
                        if (a.first_attempt === b.first_attempt) {
                          if (parseInt(b.lot_number) === parseInt(a.lot_number))
                            return a.weight_class < b.weight_class ? -1 : 1;
                          else
                            return parseInt(b.lot_number) <
                              parseInt(a.lot_number)
                              ? -1
                              : 1;
                        } else {
                          return a.first_attempt < b.first_attempt ? -1 : 1;
                        }

                      if (round === "2")
                        if (a.second_attempt === b.second_attempt) {
                          if (parseInt(b.lot_number) === parseInt(a.lot_number))
                            return a.weight_class < b.weight_class ? -1 : 1;
                          else
                            return parseInt(b.lot_number) <
                              parseInt(a.lot_number)
                              ? -1
                              : 1;
                        } else {
                          if (a.second_attempt && b.second_attempt)
                            return a.second_attempt < b.second_attempt ? -1 : 1;
                        }

                      if (round === "3")
                        if (a.third_attempt === b.third_attempt) {
                          if (parseInt(b.lot_number) === parseInt(a.lot_number))
                            return a.weight_class < b.weight_class ? -1 : 1;
                          else
                            return parseInt(b.lot_number) <
                              parseInt(a.lot_number)
                              ? -1
                              : 1;
                        } else {
                          if (a.second_attempt && b.second_attempt)
                            return a.third_attempt < b.third_attempt ? -1 : 1;
                        }
                    })
                    .map((row, index) => {
                      let rank = "-";
                      return {
                        ...row,
                        best:
                          refreshedPlatform &&
                          refreshedPlatform.third_attempt_results &&
                          refreshedPlatform.third_attempt_results[row.id]
                            ? row.third_attempt
                            : refreshedPlatform.second_attempt_results &&
                                refreshedPlatform.second_attempt_results[row.id]
                              ? row.second_attempt
                              : refreshedPlatform.first_attempt_results &&
                                  refreshedPlatform.first_attempt_results[
                                    row.id
                                  ]
                                ? row.first_attempt
                                : null,
                        BWC: [...WomensWc, ...MensWc].find(
                          (item) => item.id === row.weight_class,
                        )
                          ? [...WomensWc, ...MensWc].find(
                              (item) => item.id === row.weight_class,
                            ).name
                          : row.weight_class,
                      };
                    })
                    .map((row, index, liftersInTable) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          component="th"
                          scope="row"
                        >
                          <small>{row.lot_number}</small>
                        </TableCell>
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          component="th"
                          scope="row"
                        >
                          <small>{row.fullname}</small>
                        </TableCell>
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          align="center"
                        >
                          {row.country &&
                            countries.find((ctry) => ctry.code === row.country)
                              .flag && (
                              <img
                                src={`/flags/${countries.find((ctry) => ctry.code === row.country).flag}`}
                                loading="lazy"
                                width="30"
                                // src={`https://flagcdn.com/w20/${countries.find(ctry => ctry.code === refreshedPlatform.current.country).flag.toLowerCase()}.png`}
                                // srcSet={`https://flagcdn.com/w40/${countries.find(ctry => ctry.code === refreshedPlatform.current.country).flag.toLowerCase()}.png 2x`}
                                alt=""
                              />
                            )}
                        </TableCell>
                        <TableCell
                          style={{
                            border: "solid",
                            borderWidth: 1,
                            maxWidth: 50,
                            wordWrap: "break-world",
                          }}
                        >
                          {row.country} /{" "}
                          {teams &&
                          teams.length &&
                          teams.find((item) => item.id === row.team)
                            ? teams.find((item) => item.id === row.team).name
                            : null}
                        </TableCell>
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          component="th"
                          scope="row"
                        >
                          {row.BWC}
                        </TableCell>
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          component="th"
                          scope="row"
                        >
                          {row.bodyweight}
                        </TableCell>
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          sx={{
                            backgroundColor:
                              !refreshedPlatform.first_attempt_results
                                ? "none"
                                : refreshedPlatform.first_attempt_results[
                                      row.id
                                    ] &&
                                    refreshedPlatform.first_attempt_results[
                                      row.id
                                    ] === true
                                  ? "#008000"
                                  : refreshedPlatform.first_attempt_results &&
                                      refreshedPlatform.first_attempt_results[
                                        row.id
                                      ] === false
                                    ? "#CC0000"
                                    : "none",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <TextField
                              onChange={(e) => changeFirst(e)}
                              size="small"
                              id={`Bench1-${row.id}`}
                              defaultValue={row.first_attempt}
                              label=""
                              variant="standard"
                            />
                            <BasicMenu
                              competition={competition}
                              setRecord={(record) =>
                                setRecord(record, 1, row.id)
                              }
                              clearResult={() => clearResult(1, row.id)}
                              goodResult={() => changeResult(1, row.id, true)}
                              badResult={() => changeResult(1, row.id, false)}
                              setCurrentAttemp={() => setCurrentAttemp(1, row)}
                            />
                          </div>
                          {current &&
                            current.id === row.id &&
                            round === "1" && (
                              <>
                                <IconButton
                                  onClick={() => setstartAction(!startAction)}
                                  size="small"
                                >
                                  <AlarmOnIcon sx={{ color: "gray" }} />
                                </IconButton>
                                <IconButton
                                  onClick={() => setressetAction(!ressetAction)}
                                  size="small"
                                >
                                  <RestoreIcon sx={{ color: "gray" }} />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() => goodLift1(true, row.id)}
                                >
                                  <DoneIcon sx={{ color: "green" }} />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() => goodLift1(false, row.id)}
                                >
                                  <CloseIcon sx={{ color: "red" }} />
                                </IconButton>
                              </>
                            )}
                        </TableCell>
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          sx={{
                            backgroundColor:
                              !refreshedPlatform.second_attempt_results
                                ? "none"
                                : refreshedPlatform.second_attempt_results[
                                      row.id
                                    ] &&
                                    refreshedPlatform.second_attempt_results[
                                      row.id
                                    ] === true
                                  ? "#008000"
                                  : refreshedPlatform.second_attempt_results &&
                                      refreshedPlatform.second_attempt_results[
                                        row.id
                                      ] === false
                                    ? "#CC0000"
                                    : "none",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <TextField
                              onChange={(e) => changeSecond(e)}
                              size="small"
                              id={`Bench2-${row.id}`}
                              defaultValue={row.second_attempt}
                              label=""
                              variant="standard"
                            />
                            <BasicMenu
                              competition={competition}
                              setRecord={(record) =>
                                setRecord(record, 2, row.id)
                              }
                              clearResult={() => clearResult(2, row.id)}
                              goodResult={() => changeResult(2, row.id, true)}
                              badResult={() => changeResult(2, row.id, false)}
                              setCurrentAttemp={() => setCurrentAttemp(2, row)}
                            />
                          </div>
                          {current &&
                            current.id === row.id &&
                            round === "2" && (
                              <>
                                <IconButton
                                  onClick={() => setstartAction(!startAction)}
                                  size="small"
                                >
                                  <AlarmOnIcon sx={{ color: "gray" }} />
                                </IconButton>
                                <IconButton
                                  onClick={() => setressetAction(!ressetAction)}
                                  size="small"
                                >
                                  <RestoreIcon sx={{ color: "gray" }} />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() => goodLift2(true, row.id)}
                                >
                                  <DoneIcon sx={{ color: "green" }} />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() => goodLift2(false, row.id)}
                                >
                                  <CloseIcon sx={{ color: "red" }} />
                                </IconButton>
                              </>
                            )}
                        </TableCell>
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          sx={{
                            backgroundColor:
                              !refreshedPlatform.third_attempt_results
                                ? "none"
                                : refreshedPlatform.third_attempt_results[
                                      row.id
                                    ] &&
                                    refreshedPlatform.third_attempt_results[
                                      row.id
                                    ] === true
                                  ? "#008000"
                                  : refreshedPlatform.third_attempt_results &&
                                      refreshedPlatform.third_attempt_results[
                                        row.id
                                      ] === false
                                    ? "#CC0000"
                                    : "none",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <TextField
                              onChange={(e) => changeThird(e)}
                              size="small"
                              id={`Bench3-${row.id}`}
                              defaultValue={row.third_attempt}
                              label=""
                              variant="standard"
                            />
                            <BasicMenu
                              competition={competition}
                              setRecord={(record) => {
                                setRecord(record, 3, row.id);
                              }}
                              clearResult={() => clearResult(3, row.id)}
                              goodResult={() => changeResult(3, row.id, true)}
                              badResult={() => changeResult(3, row.id, false)}
                              setCurrentAttemp={() => setCurrentAttemp(3, row)}
                            />
                          </div>
                          {current &&
                            current.id === row.id &&
                            round === "3" && (
                              <>
                                <IconButton
                                  onClick={() => setstartAction(!startAction)}
                                  size="small"
                                >
                                  <AlarmOnIcon sx={{ color: "gray" }} />
                                </IconButton>
                                <IconButton
                                  onClick={() => setressetAction(!ressetAction)}
                                  size="small"
                                >
                                  <RestoreIcon sx={{ color: "gray" }} />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() => goodLift3(true, row.id)}
                                >
                                  <DoneIcon sx={{ color: "green" }} />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() => goodLift3(false, row.id)}
                                >
                                  <CloseIcon sx={{ color: "red" }} />
                                </IconButton>
                              </>
                            )}
                        </TableCell>
                        <TableCell
                          style={{
                            border: "solid",
                            borderWidth: 1,
                            maxWidth: 50,
                          }}
                          align="right"
                        >
                          <TextField
                            onChange={(e) => changeRack(e)}
                            size="small"
                            id={`RachHeight-${row.id}`}
                            defaultValue={row.rack_height}
                            label=""
                            variant="standard"
                          />
                        </TableCell>
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          align="right"
                        >
                          {row.best}
                        </TableCell>
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          align="right"
                        >
                          {refreshedPlatform &&
                          refreshedPlatform.first_attempt_results &&
                          refreshedPlatform.second_attempt_results &&
                          refreshedPlatform.third_attempt_results ? (
                            <>
                              {refreshedPlatform.first_attempt_results[
                                row.id
                              ] &&
                              refreshedPlatform.second_attempt_results[
                                row.id
                              ] &&
                              refreshedPlatform.third_attempt_results[row.id]
                                ? row.first_attempt +
                                  row.second_attempt +
                                  row.third_attempt
                                : null}
                              {refreshedPlatform.first_attempt_results[
                                row.id
                              ] &&
                              refreshedPlatform.second_attempt_results[
                                row.id
                              ] &&
                              !refreshedPlatform.third_attempt_results[row.id]
                                ? row.first_attempt + row.second_attempt
                                : null}
                              {refreshedPlatform.first_attempt_results[
                                row.id
                              ] &&
                              !refreshedPlatform.second_attempt_results[
                                row.id
                              ] &&
                              refreshedPlatform.third_attempt_results[row.id]
                                ? row.first_attempt + row.third_attempt
                                : null}
                              {!refreshedPlatform.first_attempt_results[
                                row.id
                              ] &&
                              refreshedPlatform.second_attempt_results[
                                row.id
                              ] &&
                              refreshedPlatform.third_attempt_results[row.id]
                                ? row.second_attempt + row.third_attempt
                                : null}
                              {refreshedPlatform.first_attempt_results[
                                row.id
                              ] &&
                              !refreshedPlatform.second_attempt_results[
                                row.id
                              ] &&
                              !refreshedPlatform.third_attempt_results[row.id]
                                ? row.first_attempt
                                : null}
                              {!refreshedPlatform.first_attempt_results[
                                row.id
                              ] &&
                              refreshedPlatform.second_attempt_results[
                                row.id
                              ] &&
                              !refreshedPlatform.third_attempt_results[row.id]
                                ? row.second_attempt
                                : null}
                              {!refreshedPlatform.first_attempt_results[
                                row.id
                              ] &&
                              !refreshedPlatform.second_attempt_results[
                                row.id
                              ] &&
                              refreshedPlatform.third_attempt_results[row.id]
                                ? row.third_attempt
                                : null}
                              {!refreshedPlatform.first_attempt_results[
                                row.id
                              ] &&
                              !refreshedPlatform.second_attempt_results[
                                row.id
                              ] &&
                              !refreshedPlatform.third_attempt_results[row.id]
                                ? "-"
                                : null}
                            </>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          align="right"
                        >
                          {pDots(row.best, row.sex, row.bodyweight)}
                        </TableCell>
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          align="right"
                        >
                          {getRank(
                            row,
                            liftersInTable.filter(
                              (lifter) => lifter.BWC === row.BWC,
                            ),
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    );

  return <Loading />;
};

function getRank(meIam, lifters) {
  lifters.sort((a, b) => {
    return a.best > b.best ? -1 : 1;
  });

  return lifters.map((lifter) => lifter.id).indexOf(meIam.id) + 1;
}

function pDots(best, sex, bw) {
  var inputLabel = "Best Lift",
    inputUnit = "kg",
    outputLabel = "Score",
    outputUnit = "pts",
    steps = 1;
  var a,
    b,
    c,
    d,
    e,
    bwsum,
    result,
    total = 0.0;
  var params = {
    1: {
      A: -7.93954283e-7,
      B: 0.000493457474,
      C: -0.1231642956,
      D: 16.0233664,
      E: 45.59224,
    },
    2: {
      A: -2.55986906e-6,
      B: 0.00116119212,
      C: -0.205352889,
      D: 17.3690866,
      E: 55.4261,
    },
  };

  var gender = sex === "m" || sex === "M" || sex === "male" ? 1 : 2;
  a = params[gender].A;
  b = params[gender].B;
  c = params[gender].C;
  d = params[gender].D;
  e = params[gender].E;

  const value = best;
  bwsum =
    a * Math.pow(bw, 4) +
    b * Math.pow(bw, 3) +
    c * Math.pow(bw, 2) +
    d * bw +
    e;
  var exactResult = (value * 500) / bwsum;
  result = Math.round(exactResult * 1000) / 1000;

  if (!isNaN(result) && result !== Infinity) {
    total += result.toFixed(3);
  }

  var totalResult = Math.round(total * 1000) / 1000;

  return `${totalResult.toFixed(3)}`;
}

function BasicMenu(props) {
  const { competition } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        size="small"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ minWidth: 40 }}
      >
        <MoreHorizIcon size="10" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            props.clearResult();
            setAnchorEl(null);
          }}
        >
          Clear Result
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.goodResult();
            setAnchorEl(null);
          }}
        >
          Mark as Good
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.badResult();
            setAnchorEl(null);
          }}
        >
          Mark as Bad
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.setCurrentAttemp();
            setAnchorEl(null);
          }}
        >
          Set as Current
        </MenuItem>
        {competition && competition.records
          ? competition.records.split(",").map((record) => (
              <MenuItem
                onClick={() => {
                  props.setRecord(record);
                  // alert(record)
                  setAnchorEl(null);
                }}
              >
                Set as {record}
              </MenuItem>
            ))
          : null}
      </Menu>
    </div>
  );
}
