import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  Edit,
  SimpleForm,
  ReferenceInput,
  TextInput,
  Create,
  NumberInput,
  AutocompleteArrayInput,
  SelectInput,
  required,
  DeleteButton,
  DateField,
  Button,
  AutocompleteInput,
  FormDataConsumer,
  useRecordContext,
  ImageField,
  ImageInput,
  useGetList,
  Loading,
  ReferenceArrayInput,
  // ArrayInput,
  // SimpleFormIterator,
} from "react-admin";
import Autocomplete from "@mui/material/Autocomplete";
import IconContentAdd from "@mui/icons-material/Add";
import { useController } from "react-hook-form";
import { styled } from "@mui/material/styles";
import PhotoIcon from "@mui/icons-material/Photo";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { useParams } from "react-router-dom";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { MensWc, WomensWc } from "./MensWc";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Typography, TextField as MUITextField } from "@mui/material";
import { Countries as countries } from "./Countries";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  Button as MUIButton,
} from "@mui/material";
import Parse from "parse";
import UserCreateButton from "./UserCreateButton";

// const user = Parse.User.current();
// console.log(user)
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const CompetitionList = () => {
  return (
    <List
      perPage={1000}
      // filters={postFilters}
      // filter={{ competition: localStorage.getItem("currentCompetition") ? localStorage.getItem("currentCompetition") : null }}
    >
      <Datagrid>
        {/*<TextField source="id" />*/}
        <TextField source="meet_name" />
        <DateField source="start_date" />
        <EditButton />
      </Datagrid>
    </List>
  );
  // const navigate = useNavigate();
  // const user = Parse.User.current();

  // const { data, total, isLoading, error } = useGetList(
  //       'competitions',
  //       {
  //           filter: {},
  //           pagination: { page: 1, perPage: 10000 },
  //           sort: { field: 'start_date', order: 'DESC' }
  //       }
  //   );
  // if (isLoading) { return <Loading />; }
  // if (error) { return <p>ERROR</p>; }

  // return (
  //   <Card>
  //     <CardHeader title="Welcome to WPPO the competition admin" />
  //     <CardContent>
  //       <Button onClick={() => navigate('/competitions/create')} variant="contained" color="secondary" label="Create competition" />
  //       <h3>My competitions</h3>
  //       <p>Click on the competition you want to get in</p>
  //       <ul>
  //           {data.map(record =>
  //               <li key={record.id}><Button onClick={()=>{
  //                 alert(record.id)
  //                 localStorage.setItem("currentCompetition", record.id);
  //                 navigate('/competitions/'+record.id)
  //               }} label={record.meet_name} /> <small>{record.start_date}</small></li>
  //           )}
  //       </ul>

  //     </CardContent>
  //   </Card>
  // );
};

export const CompetitionEdit = () => {
  return (
    <Edit redirect={false}>
      <DetailsForm />
    </Edit>
  );
};

export const CompetitionCreate = () => {
  const user = Parse.User.current();
  return (
    <Create>
      <DetailsForm />
    </Create>
  );
};

const DetailsForm = () => {
  const user = Parse.User.current();
  const { id } = useParams();

  return (
    <SimpleForm>
      <TextInput
        label="User Admin"
        source="userId"
        validate={required()}
        disabled
        readonly
        defaultValue={user._getId()}
      />
      <Box style={{ width: "100%" }} display={{ xs: "block", sm: "flex" }}>
        <Box
          flex={1}
          mr={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <h3>Competition Information</h3>
          <ImageInput
            placeholder={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "transparent",
                  borderColor: "#282c34",
                  borderStyle: "dashed",
                  borderWidth: 1,
                  borderRadius: 16,
                  width: "100%",
                  height: 160,
                }}
              >
                <PhotoIcon />
                <p>Dragg and drop or click here</p>
              </div>
            }
            multiple
            source="pictures"
            label="Logo/Icon"
            accept="image/*"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput
            label="Competition Name"
            source="meet_name"
            validate={required()}
          />
          <TextInput
            label="TD Fullname"
            source="tdname"
          />
          {/*<TextInput source="country" validate={required()} />*/}
          {/*<CountryInput label="Country"  />*/}
          <AutocompleteInput
            optionText={countryRenderer}
            optionValue="code"
            source="country"
            choices={countries}
          />
          {/*<FormDataConsumer>
              {({ formData, ...rest }) => (<>{JSON.stringify(formData)}</>)}
          </FormDataConsumer>*/}
          {/*<TextInput label="State/Province" source="state_province" validate={required()} />*/}
          <TextInput
            label="City/Town"
            source="city_town"
            validate={required()}
          />
          <TextInput type="date" source="start_date" validate={required()} />
          <h3>Users</h3>
          <ReferenceArrayInput source="users_ids" reference="User">
            <AutocompleteArrayInput
              fullwidth
              optionText="username"
              create={<UserCreateButton />}
            />
          </ReferenceArrayInput>
          {/*<UserCreateButton />*/}
          {/*<ArrayInput fullwidth source="users">
              <SimpleFormIterator inline addButton={<Button label="Add User">
                    <IconContentAdd />
                </Button>}>
                  <ReferenceInput fullwidth source="users" reference="User">
                      <SelectInput fullwidth optionText="username" create={<UserCreateButton />}/>
                  </ReferenceInput>
              </SimpleFormIterator>
          </ArrayInput>*/}
        </Box>
        <Box
          flex={1}
          ml={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <h3>Competition Rules</h3>
          <AutocompleteArrayInput
            source="divisions"
            label="Age Groups"
            validate={required()}
            choices={[
              { id: "Elite", name: "Elite" },
              { id: "Rookie", name: "Rookie" },
              { id: "Next Gen", name: "Next Gen" },
              { id: "Legend", name: "Legend" },
            ]}
            defaultValue={["Elite"]}
          />
          <AutocompleteArrayInput
            label="Men's Bodyweight Category(ies) (kg)"
            source="mens_weight_classes"
            validate={required()}
            choices={MensWc}
            defaultValue={MensWc.map((wc) => wc.id)}
          />
          <AutocompleteArrayInput
            label="Women's Bodyweight Category(ies) (kg)"
            source="womens_weight_classes"
            validate={required()}
            choices={WomensWc}
            defaultValue={WomensWc.map((wc) => wc.id)}
          />
          <SelectInput
            label="Power Lift"
            source="power_lift"
            choices={[
              { id: "yes", name: "Yes" },
              { id: "no", name: "No" },
            ]}
          />
          <SelectInput
            label="Use Club Field?"
            source="club_field"
            choices={[
              { id: "yes", name: "Yes" },
              { id: "no", name: "No" },
            ]}
          />
          <TextInput
            label="Coma separated (,) breakable records"
            source="records"
          />
        </Box>
        <Box
          flex={1}
          ml={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <h3>Weights and Loading</h3>
          <NumberInput
            label="Bench Bar + Collars weight (kg)"
            source="bench_collars_weight"
            validate={required()}
            defaultValue={25}
          />
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Discs (kg)</StyledTableCell>
                  <StyledTableCell>Pairs of discs</StyledTableCell>
                  <StyledTableCell>Color</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell>50</StyledTableCell>
                  <StyledTableCell>
                    <NumberInput
                      sx={{ width: 80 }}
                      source="fifthy_pairs"
                      label=""
                      validate={required()}
                      defaultValue={0}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <ColorInput source="fifthy_color" defaultValue="#000000" />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>25</StyledTableCell>
                  <StyledTableCell>
                    <NumberInput
                      sx={{ width: 80 }}
                      source="twentyfive_pairs"
                      label=""
                      validate={required()}
                      defaultValue={4}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <ColorInput
                      source="twentyfive_color"
                      defaultValue="#E74C3C"
                    />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>20</StyledTableCell>
                  <StyledTableCell>
                    <NumberInput
                      sx={{ width: 80 }}
                      source="twenty_pairs"
                      label=""
                      validate={required()}
                      defaultValue={1}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <ColorInput source="twenty_color" defaultValue="#3498DB" />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>15</StyledTableCell>
                  <StyledTableCell>
                    <NumberInput
                      sx={{ width: 80 }}
                      source="fifteen_pairs"
                      label=""
                      validate={required()}
                      defaultValue={1}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <ColorInput source="fifteen_color" defaultValue="#F1C40F" />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>10</StyledTableCell>
                  <StyledTableCell>
                    <NumberInput
                      sx={{ width: 80 }}
                      source="ten_pairs"
                      label=""
                      validate={required()}
                      defaultValue={1}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <ColorInput source="ten_color" defaultValue="#28B463" />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>5</StyledTableCell>
                  <StyledTableCell>
                    <NumberInput
                      sx={{ width: 80 }}
                      source="five_pairs"
                      label=""
                      validate={required()}
                      defaultValue={1}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <ColorInput source="five_color" defaultValue="#ffffff" />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>2.5</StyledTableCell>
                  <StyledTableCell>
                    <NumberInput
                      sx={{ width: 80 }}
                      source="twofive_pairs"
                      label=""
                      validate={required()}
                      defaultValue={1}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <ColorInput source="twofive_color" defaultValue="#E74C3C" />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>2</StyledTableCell>
                  <StyledTableCell>
                    <NumberInput
                      sx={{ width: 80 }}
                      source="two_pairs"
                      label=""
                      validate={required()}
                      defaultValue={1}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <ColorInput source="two_color" defaultValue="#3498DB" />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>1.5</StyledTableCell>
                  <StyledTableCell>
                    <NumberInput
                      sx={{ width: 80 }}
                      source="one_dotfive_pairs"
                      label=""
                      validate={required()}
                      defaultValue={1}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <ColorInput
                      source="one_dotfive_color"
                      defaultValue="#F1C40F"
                    />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>1</StyledTableCell>
                  <StyledTableCell>
                    <NumberInput
                      sx={{ width: 80 }}
                      source="one_pairs"
                      label=""
                      validate={required()}
                      defaultValue={1}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <ColorInput source="one_color" defaultValue="#28B463" />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>0.5</StyledTableCell>
                  <StyledTableCell>
                    <NumberInput
                      sx={{ width: 80 }}
                      source="dotfive_pairs"
                      label=""
                      validate={required()}
                      defaultValue={1}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <ColorInput source="dotfive_color" defaultValue="#ffffff" />
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </SimpleForm>
  );
};

// const countryRenderer = option => `🇦🇩 ${option.label} (${option.code})`;
const countryRenderer = (option) => ` ${option.label} (${option.code})`;

const ColorInput = (props) => {
  const input1 = useController({
    name: props.source,
    defaultValue: props.defaultValue,
  });

  return <input {...input1.field} type="color" />;
};
