import * as React from "react";
import { useEffect, useRef } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Button as MUIButton,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import {
  Button,
  useGetList,
  Loading,
  useDataProvider,
  useGetOne,
} from "react-admin";
import { useNavigate, useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import RestoreIcon from "@mui/icons-material/Restore";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Countries as countries } from "./Countries";
import { MensWc, WomensWc } from "./MensWc";
import Draggable, { DraggableCore } from "react-draggable";
import PomSubsView from "./PomSubsView";
import { Desitions } from "./Desitions";
import Parse from "parse";
const BarPlates = ({ wheight, refreshedPlatform, competition }) => {
  const ref = useRef();
  const dataProvider = useDataProvider();
  useEffect(() => {
    if (ref.current) {
      var totalWeight = parseInt(wheight); /* fixed to kg for now */
      var unit = "Kg"; /* fixed to kg for now */
      var collar = 2.5; /* always in kg */
      let fifthy_pairs = competition.fifthy_pairs;
      let twentyfive_pairs = competition.twentyfive_pairs;
      let twenty_pairs = competition.twenty_pairs;
      let fifteen_pairs = competition.fifteen_pairs;
      let ten_pairs = competition.ten_pairs;
      let five_pairs = competition.five_pairs;
      let twofive_pairs = competition.twofive_pairs;
      let two_pairs = competition.two_pairs;
      let one_dotfive_pairs = competition.one_dotfive_pairs;
      let one_pairs = competition.one_pairs;
      let dotfive_pairs = competition.dotfive_pairs;
      // load canvas
      // var ctx_kg = document.getElementById("w2" + '-cv1').getContext('2d');
      // var ctx_lbs = document.getElementById("w2" + '-cv2').getContext('2d');
      const ctx_kg = ref.current.getContext("2d");
      // clean areas
      barLoadingWidget.clearCanvas(ctx_kg);
      if (totalWeight < 25 || totalWeight > 325) {
        // barLoadingWidget.clearCanvas(ctx_lbs);
        return;
      }

      // create bar
      barLoadingWidget.drawBar(ctx_kg, Math.round(totalWeight) + " kg", 20);
      // create collars
      if (collar > 0) barLoadingWidget.drawCollar(ctx_kg, collar);
      // calculate weights
      // #1: 50kg plates at 235+ and 304+ kg

      var sideWeight = (totalWeight - 20) / 2 - collar;
      var remainingWeight = sideWeight;
      var plates = new Array();

      if (totalWeight > 235) {
        if (fifthy_pairs > 0) {
          plates.push(50);
          remainingWeight -= 50;
          fifthy_pairs -= 1;
        }
      }
      if (totalWeight > 304) {
        if (fifthy_pairs > 0) {
          plates.push(50);
          remainingWeight -= 50;
          fifthy_pairs -= 1;
        }
      }

      for (var i = twentyfive_pairs; i > 0; i--) {
        if (remainingWeight >= 25) {
          if (twentyfive_pairs > 0) {
            plates.push(25);
            remainingWeight -= 25;
            twentyfive_pairs -= 1;
          }
        }
      }

      for (var i = twenty_pairs; i > 0; i--) {
        if (remainingWeight >= 20) {
          if (twenty_pairs > 0) {
            plates.push(20);
            remainingWeight -= 20;
            twentyfive_pairs -= 1;
          }
        }
      }

      for (var i = fifteen_pairs; i > 0; i--) {
        if (remainingWeight >= 15) {
          if (fifteen_pairs > 0) {
            plates.push(15);
            remainingWeight -= 15;
            fifteen_pairs -= 1;
          }
        }
      }

      for (var i = ten_pairs; i > 0; i--) {
        if (remainingWeight >= 10) {
          if (ten_pairs > 0) {
            plates.push(10);
            remainingWeight -= 10;
            ten_pairs -= 1;
          }
        }
      }

      for (var i = five_pairs; i > 0; i--) {
        if (remainingWeight >= 5) {
          if (five_pairs > 0) {
            plates.push(5);
            remainingWeight -= 5;
            five_pairs -= 1;
          }
        }
      }

      for (var i = twofive_pairs; i > 0; i--) {
        if (remainingWeight >= 2.5) {
          if (twofive_pairs > 0) {
            plates.push(2.5);
            remainingWeight -= 2.5;
            twofive_pairs -= 1;
          }
        }
      }

      for (var i = two_pairs; i > 0; i--) {
        if (remainingWeight >= 2) {
          if (two_pairs > 0) {
            plates.push(2);
            remainingWeight -= 2;
            two_pairs -= 2;
          }
        }
      }

      for (var i = one_dotfive_pairs; i > 0; i--) {
        if (remainingWeight >= 1.5) {
          if (one_pairs > 0) {
            plates.push(1.5);
            remainingWeight -= 1.5;
            one_pairs -= 1.5;
          }
        }
      }

      for (var i = one_pairs; i > 0; i--) {
        if (remainingWeight >= 1) {
          if (one_pairs > 0) {
            plates.push(1);
            remainingWeight -= 1;
            one_pairs -= 1;
          }
        }
      }

      for (var i = dotfive_pairs; i > 0; i--) {
        if (remainingWeight >= 0.5) {
          if (dotfive_pairs > 0) {
            plates.push(0.5);
            remainingWeight -= 0.5;
            dotfive_pairs -= 1;
          }
        }
      }

      // print plates
      for (var i = plates.length - 1; i >= 0; i--) {
        barLoadingWidget.drawPlate(ctx_kg, plates[i], "kg");
      }
      // show remaining
      if (remainingWeight)
        barLoadingWidget.drawNotes(
          ctx_kg,
          "Missing weight = " + remainingWeight.toFixed(3) + " kg",
        );
    }
  }, [wheight]);

  const barLoadingWidget = {
    x: 0,
    platesKg: {
      0.5: {
        colour: competition.dotfive_color,
        text: "black",
        height: 75,
        width: 20,
      },
      1: {
        colour: competition.one_color,
        text: "black",
        height: 85,
        width: 20,
      },
      1.5: {
        colour: competition.one_dotfive_color,
        text: "black",
        height: 90,
        width: 20,
      },
      2: {
        colour: competition.two_color,
        text: "black",
        height: 95,
        width: 20,
      },
      2.5: {
        colour: competition.twofive_color,
        text: "white",
        height: 100,
        width: 22,
      },
      5: {
        colour: competition.five_color,
        text: "black",
        height: 180,
        width: 24,
      },
      10: {
        colour: competition.ten_color,
        text: "black",
        height: 180,
        width: 24,
      },
      15: {
        colour: competition.fifteen_color,
        text: "black",
        height: 180,
        width: 24,
      },
      20: {
        colour: competition.twenty_color,
        text: "black",
        height: 180,
        width: 24,
      },
      25: {
        colour: competition.twentyfive_color,
        text: "black",
        height: 180,
        width: 30,
      },
      50: {
        colour: competition.fifthy_color,
        text: "white",
        height: 180,
        width: 30,
      },
    },
    platesLbs: {
      2.5: {
        colour: "gray",
        text: "white",
        height: 75,
        width: 20,
      },
      5: {
        colour: "gray",
        text: "white",
        height: 85,
        width: 22,
      },
      10: {
        colour: "gray",
        text: "white",
        height: 90,
        width: 24,
      },
      25: {
        colour: "gray",
        text: "white",
        height: 95,
        width: 26,
      },
      35: {
        colour: "gray",
        text: "white",
        height: 100,
        width: 26,
      },
      45: {
        colour: "gray",
        text: "white",
        height: 180,
        width: 28,
      },
      100: {
        colour: "gray",
        text: "white",
        height: 180,
        width: 30,
      },
    },
    clearCanvas: function (ctx, totalWeight) {
      ctx.clearRect(0, 0, 300, 300);
      ctx.font = "12px Arial";
      this.x = 234;
    },
    drawBar: function (ctx, totalWeight, barWeight) {
      var barHeight = 24;
      ctx.fillStyle = "silver";
      ctx.fillRect(5, 150 - barHeight / 2, 270, barHeight);
      ctx.fillStyle = "gray";
      ctx.strokeRect(5, 150 - barHeight / 2, 270, barHeight);
      ctx.fillText(barWeight, 30, 154);
      var font = ctx.font;
      ctx.font = "34px Arial";
      ctx.fillStyle = "black";
      ctx.fillText(totalWeight, 110, 34);
      ctx.font = font;
    },
    drawCollar: function (ctx, collarWeight) {
      var collarHeight = 48,
        collarWidth = 24;
      ctx.beginPath();
      ctx.lineWidth = 4;
      ctx.moveTo(this.x + collarWidth / 2, 150 - collarHeight / 2);
      ctx.lineTo(this.x + (3 * collarWidth) / 4, 150 - collarHeight / 2 - 10);
      ctx.stroke();
      ctx.lineWidth = 1;
      ctx.fillStyle = "silver";
      ctx.fillRect(this.x, 150 - collarHeight / 2, collarWidth, collarHeight);
      ctx.fillStyle = "gray";
      ctx.strokeRect(this.x, 150 - collarHeight / 2, collarWidth, collarHeight);
      var textWidth = ctx.measureText("2.5").width;
      ctx.fillText(collarWeight, this.x + (collarWidth - textWidth) / 2, 154);
    },
    drawPlate: function (ctx, plate, unit) {
      var plateDefinition =
        unit === "lbs"
          ? this.platesLbs[plate.toString()]
          : this.platesKg[plate.toString()];
      this.x -= plateDefinition.width;
      ctx.fillStyle = plateDefinition.colour;
      ctx.fillRect(
        this.x,
        150 - plateDefinition.height / 2,
        plateDefinition.width,
        plateDefinition.height,
      );
      ctx.fillStyle = "gray";
      ctx.strokeRect(
        this.x,
        150 - plateDefinition.height / 2,
        plateDefinition.width,
        plateDefinition.height,
      );
      var textWidth = ctx.measureText(plate).width;
      ctx.fillStyle = plateDefinition.text;
      ctx.fillText(
        plate,
        this.x + (plateDefinition.width - textWidth) / 2,
        154,
      );
    },
    drawNotes: function (ctx, text) {
      ctx.font = "18px Arial";
      ctx.fillStyle = "gray";
      ctx.fillText(text, 10, 280);
    },
  };
  return <canvas ref={ref} id="w2-cv1" width="280" height="200"></canvas>;
};

export const Board = () => {
  const navigate = useNavigate();
  const user = Parse.User.current();
  const { platform } = useParams();
  const dataProvider = useDataProvider();
  const [round, setRound] = React.useState("1");
  const [ldecisions, setLDecisions] = React.useState({});
  const [rdecisions, setrDecisions] = React.useState({});
  const [cdecisions, setCDecisions] = React.useState({});
  const { data: competition } = useGetOne("competitions", {
    id: localStorage.getItem("currentCompetition"),
  });
  const {
    data: lifters,
    total,
    isLoading,
    error,
  } = useGetList("lifters", {
    filter: {
      platform,
    },
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "fullname", order: "ASC" },
  });

  const { data: sessions } = useGetList("platforms", {
    filter: {
      competition: localStorage.getItem("currentCompetition"),
    },
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "fullname", order: "ASC" },
  });

  const [refreshedLifters, setRefreshedLifters] = React.useState(
    lifters || null,
  );

  const {
    data: teams,
    total2,
    isLoading2,
    error2,
  } = useGetList("teams", {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "name", order: "ASC" },
  });

  const {
    data: record,
    isLoading3,
    error3,
  } = useGetOne("platforms", { id: platform });
  const [refreshedPlatform, setRefreshedPlatform] = React.useState(
    record || null,
  );

  const livequery = async () => {
    // const self = this;
    console.log("entro a las subscripción")
    if (!refreshedPlatform && platform) {
      const { data: recordT } = await dataProvider.getOne("platforms", {
        id: platform,
      });
      setRefreshedPlatform(recordT);
    }

    const query = new Parse.Query("platforms");
    query.equalTo("objectId", platform);
    const subscription = await query.subscribe();
    subscription.on("update", async (object) => {
      console.log("Entro a update de la primera subscripción");
      setRound(object.get("round"));
      setRefreshedPlatform(object.toJSON());
    });

    const queryLifters = new Parse.Query("lifters");
    queryLifters.equalTo("platform", platform);
    const subscriptionLifers = await queryLifters.subscribe();
    subscriptionLifers.on("update", async (object) => {
      //Obtengo al current
      const { data: liftersT } = await dataProvider.getList("lifters", {
        filter: {
          platform,
        },
        pagination: { page: 1, perPage: 10000 },
        sort: { field: "fullname", order: "ASC" },
      });
      setRefreshedLifters(liftersT);
    });
  };

  React.useEffect(() => {
    if (platform) {
      livequery();
    }
  }, [platform]);

  React.useEffect(() => {
    if (!isLoading && lifters.length && !refreshedLifters)
      setRefreshedLifters(lifters);
  }, [isLoading]);

  React.useEffect(() => {
    if (refreshedPlatform) setRound(refreshedPlatform.round);
  }, [refreshedPlatform]);

  if (
    isLoading ||
    isLoading2 ||
    isLoading3 ||
    !refreshedPlatform ||
    !competition
  ) {
    return <Loading />;
  }
  if (error || error2 || error3) {
    return <p>ERROR</p>;
  }

  return (
    <Card className="darkside">
      {/*<CardHeader title={`${record.name}`} />*/}
      <CardContent>
        {refreshedPlatform && refreshedPlatform.current ? (
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <h3 style={{ margin: 0, fontSize: 20 }}>
                <span style={{ color: "#008000", fontSize: 30 }}>
                  {refreshedPlatform &&
                    refreshedPlatform.current &&
                    refreshedPlatform.current.lot_number}
                  .{" "}
                </span>
                {refreshedPlatform &&
                  refreshedPlatform.current &&
                  refreshedPlatform.current.fullname}
              </h3>
              <h3 style={{ margin: 0, fontSize: 20 }}>
                Round {refreshedPlatform.round}
                {" , "}
                {[...MensWc, ...WomensWc].find(
                  (item) => item.id === refreshedPlatform.current.weight_class,
                )
                  ? [...MensWc, ...WomensWc].find(
                      (item) =>
                        item.id === refreshedPlatform.current.weight_class,
                    ).name
                  : null}
              </h3>
              <h3 style={{ margin: 0 }}>
                {teams &&
                teams.length &&
                teams.find((item) => item.id === refreshedPlatform.current.team)
                  ? teams.find(
                      (item) => item.id === refreshedPlatform.current.team,
                    ).name
                  : null}
              </h3>
              {countries.find(
                (ctry) => ctry.code === refreshedPlatform.current.country,
              ) &&
              countries.find(
                (ctry) => ctry.code === refreshedPlatform.current.country,
              ).flag ? (
                <img
                  src={`/flags/${countries.find((ctry) => ctry.code === refreshedPlatform.current.country).flag}`}
                  loading="lazy"
                  width="40"
                  // src={`https://flagcdn.com/w20/${countries.find(ctry => ctry.code === refreshedPlatform.current.country).flag.toLowerCase()}.png`}
                  // srcSet={`https://flagcdn.com/w40/${countries.find(ctry => ctry.code === refreshedPlatform.current.country).flag.toLowerCase()}.png 2x`}
                  alt=""
                />
              ) : null}
              <h3 style={{ margin: 0, fontSize: 20, margin: 0 }}>
                {refreshedPlatform.current.country}
              </h3>
              <Desitions platform={platform} />
            </Grid>
            <Grid
              item
              xs={7}
              style={{
                display: "flex",
                textAlign: "right",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ display: "block", marginRight: 0 }}>
                {refreshedLifters &&
                  refreshedPlatform &&
                  refreshedLifters.find(
                    (lifter) => lifter.id === refreshedPlatform.current.id,
                  ) && (
                    <BarPlates
                      competition={competition}
                      refreshedPlatform={refreshedPlatform}
                      wheight={
                        round === "1"
                          ? refreshedLifters.find(
                              (lifter) =>
                                lifter.id === refreshedPlatform.current.id,
                            )["first_attempt"]
                          : round === "2"
                            ? refreshedLifters.find(
                                (lifter) =>
                                  lifter.id === refreshedPlatform.current.id,
                              )["second_attempt"]
                            : round === "3"
                              ? refreshedLifters.find(
                                  (lifter) =>
                                    lifter.id === refreshedPlatform.current.id,
                                )["third_attempt"]
                              : null
                      }
                    />
                  )}
              </div>
              <div style={{ display: "block" }}>
                {refreshedLifters &&
                  refreshedPlatform &&
                  refreshedLifters.find(
                    (lifter) => lifter.id === refreshedPlatform.current.id,
                  ) && (
                    <h1 style={{ fontSize: 40, margin: 0 }}>
                      {round === "1"
                        ? refreshedLifters.find(
                            (lifter) =>
                              lifter.id === refreshedPlatform.current.id,
                          )["first_attempt"]
                        : round === "2"
                          ? refreshedLifters.find(
                              (lifter) =>
                                lifter.id === refreshedPlatform.current.id,
                            )["second_attempt"]
                          : round === "3"
                            ? refreshedLifters.find(
                                (lifter) =>
                                  lifter.id === refreshedPlatform.current.id,
                              )["third_attempt"]
                            : null}{" "}
                      Kg
                    </h1>
                  )}
                <h3 style={{ fontSize: 40, margin: 0 }}>
                  Rack{" "}
                  {refreshedPlatform &&
                    refreshedLifters &&
                    refreshedLifters.find(
                      (lifter) => lifter.id === refreshedPlatform.current.id,
                    ) &&
                    refreshedLifters.find(
                      (lifter) => lifter.id === refreshedPlatform.current.id,
                    )["rack_height"]}
                </h3>
                <PomSubsView refreshedPlatform={refreshedPlatform} />
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <h3 style={{ margin: 0, fontSize: 20, margin: 0 }}>
                <span style={{ color: "#008000", fontSize: 40 }}>. </span>{" "}
              </h3>
              <h3 style={{ margin: 0, fontSize: 20, margin: 0 }}>Round:</h3>
              <h3></h3>
              <h3 style={{ margin: 0, fontSize: 20, margin: 0 }}></h3>
              <Desitions platform={platform} />
            </Grid>
            <Grid
              item
              xs={7}
              style={{
                display: "flex",
                textAlign: "right",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ display: "block", marginRight: 20 }}></div>
              <div style={{ display: "block" }}>
                <h1 style={{ fontSize: 60, margin: 0 }}> Kg</h1>
                <h3 style={{ fontSize: 60, margin: 0 }}>Rack </h3>
                <PomSubsView refreshedPlatform={refreshedPlatform} />
              </div>
            </Grid>
          </Grid>
        )}

        {/*<TableContainer component={Paper}>*/}
        <Table
          width="100%"
          sx={{ minWidth: 650, color: "#ffffff" }}
          size="small"
          aria-label="simple table"
          className="boardTable"
        >
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  border: "solid",
                  borderWidth: 1,
                  color: "white",
                  width: 50,
                }}
                align="right"
              >
                Lot
              </TableCell>
              <TableCell
                style={{
                  border: "solid",
                  borderWidth: 1,
                  color: "white",
                  width: 200,
                }}
                align="center"
              >
                Name
              </TableCell>
              {/*<TableCell
                style={{
                  border: "solid",
                  borderWidth: 1,
                  color: "white",
                  width: 60,
                }}
                align="center"
              >
                Flag
              </TableCell>*/}
              <TableCell
                style={{
                  border: "solid",
                  borderWidth: 1,
                  color: "white",
                  maxWidth: 60,
                }}
                align="center"
              >
                Country / Team
              </TableCell>
              <TableCell
                style={{ border: "solid", borderWidth: 1, color: "white" }}
                align="center"
              >
                BWC
              </TableCell>
              <TableCell
                style={{ border: "solid", borderWidth: 1, color: "white" }}
                align="center"
              >
                BW
              </TableCell>
              <TableCell
                style={{ border: "solid", borderWidth: 1, color: "white" }}
                align="center"
              >
                Rack Height
              </TableCell>
              <TableCell
                style={{ border: "solid", borderWidth: 1, color: "white" }}
                align="center"
              >
                1
              </TableCell>
              <TableCell
                style={{ border: "solid", borderWidth: 1, color: "white" }}
                align="center"
              >
                2
              </TableCell>
              <TableCell
                style={{ border: "solid", borderWidth: 1, color: "white" }}
                align="center"
              >
                3
              </TableCell>
              <TableCell
                style={{ border: "solid", borderWidth: 1, color: "white" }}
                align="center"
              >
                Best
              </TableCell>
              {/*<TableCell
                style={{ border: "solid", borderWidth: 1, color: "white" }}
                align="center"
              >
                Best Rank
              </TableCell>
              <TableCell
                style={{ border: "solid", borderWidth: 1, color: "white" }}
                align="center"
              >
                Total
              </TableCell>
              <TableCell
                style={{ border: "solid", borderWidth: 1, color: "white" }}
                align="center"
              >
                Total Rank
              </TableCell>
              <TableCell
                style={{ border: "solid", borderWidth: 1, color: "white" }}
                align="center"
              >
                pDOTS
              </TableCell>*/}
              <TableCell
                style={{ border: "solid", borderWidth: 1, color: "white" }}
                align="center"
              >
                Rank
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {refreshedLifters &&
              refreshedLifters
                .sort((a, b) => {
                  if (round === "1")
                    if (a.first_attempt === b.first_attempt) {
                      if (parseInt(b.lot_number) === parseInt(a.lot_number))
                        return a.weight_class < b.weight_class ? -1 : 1;
                      else
                        return parseInt(b.lot_number) < parseInt(a.lot_number)
                          ? -1
                          : 1;
                    } else {
                      return a.first_attempt < b.first_attempt ? -1 : 1;
                    }

                  if (round === "2")
                    if (a.second_attempt === b.second_attempt) {
                      if (parseInt(b.lot_number) === parseInt(a.lot_number))
                        return a.weight_class < b.weight_class ? -1 : 1;
                      else
                        return parseInt(b.lot_number) < parseInt(a.lot_number)
                          ? -1
                          : 1;
                    } else {
                      return a.second_attempt < b.second_attempt ? -1 : 1;
                    }

                  if (round === "3")
                    if (a.third_attempt === b.third_attempt) {
                      if (parseInt(b.lot_number) === parseInt(a.lot_number))
                        return a.weight_class < b.weight_class ? -1 : 1;
                      else
                        return parseInt(b.lot_number) < parseInt(a.lot_number)
                          ? -1
                          : 1;
                    } else {
                      return a.third_attempt < b.third_attempt ? -1 : 1;
                    }
                })
                .map((row, index) => {
                  let rank = "-";
                  return {
                    ...row,
                    best:
                      refreshedPlatform &&
                      refreshedPlatform.third_attempt_results &&
                      refreshedPlatform.third_attempt_results[row.id]
                        ? row.third_attempt
                        : refreshedPlatform.second_attempt_results &&
                            refreshedPlatform.second_attempt_results[row.id]
                          ? row.second_attempt
                          : refreshedPlatform.first_attempt_results &&
                              refreshedPlatform.first_attempt_results[row.id]
                            ? row.first_attempt
                            : null,
                    BWC: [...WomensWc, ...MensWc].find(
                      (item) => item.id === row.weight_class,
                    )
                      ? [...WomensWc, ...MensWc].find(
                          (item) => item.id === row.weight_class,
                        ).name
                      : row.weight_class,
                    total: 0,
                    // row &&
                    // row.id &&
                    // row.first_attempt &&
                    // row.second_attempt &&
                    // row.third_attempt &&
                    // refreshedPlatform &&
                    // refreshedPlatform.first_attempt_results &&
                    // refreshedPlatform.second_attempt_results &&
                    // refreshedPlatform.third_attempt_results &&
                    // refreshedPlatform.first_attempt_results[row.id] &&
                    // refreshedPlatform.second_attempt_results[row.id] &&
                    // refreshedPlatform.third_attempt_results[row.id]
                    //   ? row.first_attempt +
                    //     row.second_attempt +
                    //     row.third_attempt
                    //   : refreshedPlatform.first_attempt_results[row.id] &&
                    //       refreshedPlatform.second_attempt_results[row.id] &&
                    //       !refreshedPlatform.third_attempt_results[row.id]
                    //     ? row.first_attempt + row.second_attempt
                    //     : refreshedPlatform.first_attempt_results[row.id] &&
                    //         !refreshedPlatform.second_attempt_results[
                    //           row.id
                    //         ] &&
                    //         refreshedPlatform.third_attempt_results[row.id]
                    //       ? row.first_attempt + row.third_attempt
                    //       : !refreshedPlatform.first_attempt_results[
                    //             row.id
                    //           ] &&
                    //           refreshedPlatform.second_attempt_results[
                    //             row.id
                    //           ] &&
                    //           refreshedPlatform.third_attempt_results[row.id]
                    //         ? row.second_attempt + row.third_attempt
                    //         : refreshedPlatform.first_attempt_results[
                    //               row.id
                    //             ] &&
                    //             !refreshedPlatform.second_attempt_results[
                    //               row.id
                    //             ] &&
                    //             !refreshedPlatform.third_attempt_results[
                    //               row.id
                    //             ]
                    //           ? row.first_attempt
                    //           : !refreshedPlatform.first_attempt_results[
                    //                 row.id
                    //               ] &&
                    //               refreshedPlatform.second_attempt_results[
                    //                 row.id
                    //               ] &&
                    //               !refreshedPlatform.third_attempt_results[
                    //                 row.id
                    //               ]
                    //             ? row.second_attempt
                    //             : !refreshedPlatform.first_attempt_results[
                    //                   row.id
                    //                 ] &&
                    //                 !refreshedPlatform.second_attempt_results[
                    //                   row.id
                    //                 ] &&
                    //                 refreshedPlatform.third_attempt_results[
                    //                   row.id
                    //                 ]
                    //               ? row.third_attempt
                    //               : !refreshedPlatform.first_attempt_results[
                    //                     row.id
                    //                   ] &&
                    //                   !refreshedPlatform
                    //                     .second_attempt_results[row.id] &&
                    //                   !refreshedPlatform
                    //                     .third_attempt_results[row.id]
                    //                 ? 0
                    //                 : 0,
                  };
                })
                .map((row, index, mainarr) => {
                  const nextIndex =
                    refreshedPlatform && refreshedPlatform.current
                      ? mainarr.findIndex(
                          (item) => item.id === refreshedPlatform.current.id,
                        )
                      : -1;
                  // console.log(nextIndex)
                  // console.log(mainarr)
                  const nextLifter =
                    nextIndex !== -1 && mainarr[nextIndex + 1]
                      ? mainarr[nextIndex + 1]
                      : null;

                  return (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          color: "yellow",
                        }}
                        align="right"
                      >
                        {row.lot_number}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          color:
                            refreshedPlatform &&
                            refreshedPlatform.current &&
                            refreshedPlatform.current.id === row.id
                              ? "black"
                              : refreshedPlatform &&
                                  refreshedPlatform.current &&
                                  nextLifter &&
                                  nextLifter.id === row.id
                                ? "black"
                                : "yellow",
                        }}
                        component="th"
                        scope="row"
                        className={
                          refreshedPlatform &&
                          refreshedPlatform.current &&
                          refreshedPlatform.current.id === row.id
                            ? "black"
                            : refreshedPlatform &&
                                refreshedPlatform.current &&
                                nextLifter &&
                                nextLifter.id === row.id
                              ? "black"
                              : "yellow"
                        }
                        sx={{
                          fontSize: { xs: 10, md: 12 },
                          backgroundColor:
                            refreshedPlatform &&
                            refreshedPlatform.current &&
                            refreshedPlatform.current.id === row.id
                              ? "yellow"
                              : refreshedPlatform &&
                                  refreshedPlatform.current &&
                                  nextLifter &&
                                  nextLifter.id === row.id
                                ? "#00e3ff"
                                : "black",
                          color:
                            refreshedPlatform &&
                            refreshedPlatform.current &&
                            refreshedPlatform.current.id === row.id
                              ? "black"
                              : refreshedPlatform &&
                                  refreshedPlatform.current &&
                                  nextLifter &&
                                  nextLifter.id === row.id
                                ? "black"
                                : "yellow",
                        }}
                      >
                        {row.fullname}
                      </TableCell>
                      {/*<TableCell
                                              style={{ border: "solid", borderWidth: 1 }}
                                              align="center"
                                            >
                                              {countries.find((ctry) => ctry.code === row.country) &&
                                                countries.find((ctry) => ctry.code === row.country)
                                                  .flag && (
                                                  <img
                                                    src={`/flags/${countries.find((ctry) => ctry.code === row.country).flag}`}
                                                    loading="lazy"
                                                    width="30"
                                                    // src={`https://flagcdn.com/w20/${countries.find(ctry => ctry.code === refreshedPlatform.current.country).flag.toLowerCase()}.png`}
                                                    // srcSet={`https://flagcdn.com/w40/${countries.find(ctry => ctry.code === refreshedPlatform.current.country).flag.toLowerCase()}.png 2x`}
                                                    alt=""
                                                  />
                                                )}
                                            </TableCell>*/}
                      <TableCell
                        sx={{
                          border: "solid",
                          borderWidth: 1,
                          color: "yellow",
                          fontSize: { xs: 10, md: 10 },
                        }}
                      >
                        {row.country} /{" "}
                        {teams &&
                        teams.length &&
                        teams.find((item) => item.id === row.team)
                          ? teams.find((item) => item.id === row.team).name
                          : null}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "solid",
                          borderWidth: 1,
                          color: "yellow",
                          fontSize: { xs: 10, md: 12 },
                        }}
                        component="th"
                        scope="row"
                      >
                        {[...WomensWc, ...MensWc].find(
                          (item) => item.id === row.weight_class,
                        )
                          ? [...WomensWc, ...MensWc].find(
                              (item) => item.id === row.weight_class,
                            ).name
                          : row.weight_class}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "solid",
                          borderWidth: 1,
                          color: "yellow",
                        }}
                        component="th"
                        scope="row"
                      >
                        {row.bodyweight}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "solid",
                          borderWidth: 1,
                          color: "yellow",
                        }}
                        align="right"
                      >
                        {row.rack_height}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "solid",
                          borderWidth: 1,
                          fontSize: { xs: 14, md: 18 },
                          fontWeight: "bold",
                        }}
                        align="right"
                        className={
                          refreshedPlatform &&
                          refreshedPlatform.current &&
                          refreshedPlatform &&
                          refreshedPlatform.current.id === row.id &&
                          round === "1"
                            ? "black"
                            : refreshedPlatform &&
                                (!refreshedPlatform.third_attempt_results ||
                                  !refreshedPlatform.third_attempt_results[
                                    row.id
                                  ]) &&
                                (!refreshedPlatform.second_attempt_results ||
                                  !refreshedPlatform.second_attempt_results[
                                    row.id
                                  ]) &&
                                refreshedPlatform.first_attempt_results &&
                                refreshedPlatform.first_attempt_results[row.id]
                              ? "black"
                              : "white"
                        }
                        sx={{
                          backgroundColor:
                            refreshedPlatform &&
                            refreshedPlatform.current &&
                            refreshedPlatform.current.id === row.id &&
                            round === "1"
                              ? "yellow"
                              : refreshedPlatform &&
                                  refreshedPlatform.current &&
                                  nextLifter &&
                                  nextLifter.id === row.id &&
                                  round === "1"
                                ? "#00e3ff"
                                : refreshedPlatform &&
                                    (!refreshedPlatform.third_attempt_results ||
                                      !refreshedPlatform.third_attempt_results[
                                        row.id
                                      ]) &&
                                    (!refreshedPlatform.second_attempt_results ||
                                      !refreshedPlatform.second_attempt_results[
                                        row.id
                                      ]) &&
                                    refreshedPlatform.first_attempt_results &&
                                    refreshedPlatform.first_attempt_results[
                                      row.id
                                    ]
                                  ? "#008000"
                                  : "black",
                          color:
                            refreshedPlatform &&
                            refreshedPlatform.first_attempt_results &&
                            refreshedPlatform.first_attempt_results[row.id] &&
                            refreshedPlatform.first_attempt_results[row.id] ===
                              true
                              ? "#008000"
                              : refreshedPlatform.first_attempt_results &&
                                  refreshedPlatform.first_attempt_results[
                                    row.id
                                  ] === false
                                ? "#CC0000"
                                : "yellow",
                        }}
                        component="th"
                      >
                        {row.first_attempt}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "solid",
                          borderWidth: 1,
                          fontSize: { xs: 14, md: 18 },
                          fontWeight: "bold",
                        }}
                        align="right"
                        className={
                          refreshedPlatform &&
                          refreshedPlatform.current &&
                          refreshedPlatform.current.id === row.id &&
                          round === "2"
                            ? "black"
                            : refreshedPlatform &&
                                (!refreshedPlatform.third_attempt_results ||
                                  !refreshedPlatform.third_attempt_results[
                                    row.id
                                  ]) &&
                                refreshedPlatform.second_attempt_results &&
                                refreshedPlatform.second_attempt_results[row.id]
                              ? "black"
                              : "white"
                        }
                        sx={{
                          backgroundColor:
                            refreshedPlatform &&
                            refreshedPlatform.current &&
                            refreshedPlatform.current.id === row.id &&
                            round === "2"
                              ? "yellow"
                              : refreshedPlatform &&
                                  refreshedPlatform.current &&
                                  nextLifter &&
                                  nextLifter.id === row.id &&
                                  round === "2"
                                ? "#00e3ff"
                                : refreshedPlatform &&
                                    (!refreshedPlatform.third_attempt_results ||
                                      !refreshedPlatform.third_attempt_results[
                                        row.id
                                      ]) &&
                                    refreshedPlatform.second_attempt_results &&
                                    refreshedPlatform.second_attempt_results[
                                      row.id
                                    ]
                                  ? "#008000"
                                  : "black",
                          color:
                            refreshedPlatform &&
                            refreshedPlatform.second_attempt_results &&
                            refreshedPlatform.second_attempt_results[row.id] &&
                            refreshedPlatform.second_attempt_results[row.id] ===
                              true
                              ? "#008000"
                              : refreshedPlatform.second_attempt_results &&
                                  refreshedPlatform.second_attempt_results[
                                    row.id
                                  ] === false
                                ? "#CC0000"
                                : "yellow",
                        }}
                        component="th"
                      >
                        {row.second_attempt}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "solid",
                          borderWidth: 1,
                          fontSize: { xs: 14, md: 18 },
                          fontWeight: "bold",
                        }}
                        align="right"
                        className={
                          refreshedPlatform &&
                          refreshedPlatform.current &&
                          refreshedPlatform.current.id === row.id &&
                          round === "3"
                            ? "black"
                            : refreshedPlatform.third_attempt_results &&
                                refreshedPlatform.third_attempt_results[row.id]
                              ? "black"
                              : "white"
                        }
                        sx={{
                          backgroundColor:
                            refreshedPlatform &&
                            refreshedPlatform.current &&
                            refreshedPlatform.current.id === row.id &&
                            round === "3"
                              ? "yellow"
                              : refreshedPlatform &&
                                  refreshedPlatform.current &&
                                  nextLifter &&
                                  nextLifter.id === row.id &&
                                  round === "3"
                                ? "#00e3ff"
                                : refreshedPlatform.third_attempt_results &&
                                    refreshedPlatform.third_attempt_results[
                                      row.id
                                    ]
                                  ? "#008000"
                                  : "black",
                          color:
                            refreshedPlatform &&
                            refreshedPlatform.third_attempt_results &&
                            refreshedPlatform.third_attempt_results[row.id] &&
                            refreshedPlatform.third_attempt_results[row.id] ===
                              true
                              ? "#008000"
                              : refreshedPlatform.third_attempt_results &&
                                  refreshedPlatform.third_attempt_results[
                                    row.id
                                  ] === false
                                ? "#CC0000"
                                : "yellow",
                        }}
                        component="th"
                      >
                        {row.third_attempt}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "solid",
                          borderWidth: 1,
                          fontSize: { xs: 14, md: 16 },
                          fontWeight: "bold",
                          color: "yellow",
                        }}
                        align="right"
                      >
                        {refreshedPlatform &&
                        refreshedPlatform.third_attempt_results &&
                        refreshedPlatform.third_attempt_results[row.id]
                          ? row.third_attempt
                          : refreshedPlatform.second_attempt_results &&
                              refreshedPlatform.second_attempt_results[row.id]
                            ? row.second_attempt
                            : refreshedPlatform.first_attempt_results &&
                                refreshedPlatform.first_attempt_results[row.id]
                              ? row.first_attempt
                              : null}
                      </TableCell>
                      {/*<TableCell
                                              sx={{
                                                border: "solid",
                                                borderWidth: 1,
                                                fontSize: { xs: 16, md: 16 },
                                                fontWeight: "bold",
                                                color: "yellow",
                                              }}
                                              align="right"
                                            >
                                              {getBestRank(
                                                row,
                                                mainarr.filter((lifter) => lifter.BWC === row.BWC),
                                              )}
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                border: "solid",
                                                borderWidth: 1,
                                                fontSize: { xs: 14, md: 16 },
                                                fontWeight: "bold",
                                                color: "yellow",
                                              }}
                                            >
                                              {row.total}
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                border: "solid",
                                                borderWidth: 1,
                                                fontSize: { xs: 14, md: 16 },
                                                fontWeight: "bold",
                                                color: "yellow",
                                              }}
                                              align="right"
                                            >
                                              {getTotalRank(
                                                row,
                                                mainarr.filter((lifter) => lifter.BWC === row.BWC),
                                              )}
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                border: "solid",
                                                borderWidth: 1,
                                                fontSize: 12,
                                                color: "yellow",
                                              }}
                                              align="right"
                                            >
                                              {pDots(row.best, row.sex, row.bodyweight)}
                                            </TableCell>*/}
                      <TableCell
                        sx={{
                          border: "solid",
                          borderWidth: 1,
                          fontSize: { xs: 14, md: 16 },
                          fontWeight: "bold",
                          color: "yellow",
                        }}
                        align="right"
                      >
                        {getRank(
                          row,
                          mainarr.filter((lifter) => lifter.BWC === row.BWC),
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            <TableRow></TableRow>
          </TableBody>
        </Table>
        {/*</TableContainer>*/}
        {sessions && sessions.length && (
          <Select
            style={{ backgroundColor: "gray" }}
            onChange={(event) => {
              alert(event.target.value);
              window.location.href = `https://powerlift.worldparapowerlifting.org/#/board/${event.target.value}`;
            }}
          >
            {sessions.map((session) => (
              <MenuItem value={session.id}>{session.name}</MenuItem>
            ))}
          </Select>
        )}
      </CardContent>
    </Card>
  );
};

function getBestRank(meIam, lifters) {
  lifters.sort((a, b) => {
    return parseFloat(b.best) - parseFloat(a.best);
  });

  return lifters.map((lifter) => lifter.id).indexOf(meIam.id) + 1;
}

function getTotalRank(meIam, lifters) {
  lifters.sort((a, b) => {
    return parseFloat(b.total) - parseFloat(a.total);
  });

  return lifters.map((lifter) => lifter.id).indexOf(meIam.id) + 1;
}

function getRank(meIam, lifters) {
  lifters.sort((a, b) => {
    const apdots = pDots(a.best, a.sex, a.bodyweight);
    const bpdots = pDots(b.best, b.sex, b.bodyweight);
    return parseFloat(bpdots) - parseFloat(apdots);
  });

  return lifters.map((lifter) => lifter.id).indexOf(meIam.id) + 1;
}

function pDots(best, sex, bw) {
  var inputLabel = "Best Lift",
    inputUnit = "kg",
    outputLabel = "Score",
    outputUnit = "pts",
    steps = 1;
  var a,
    b,
    c,
    d,
    e,
    bwsum,
    result,
    total = 0.0;
  var params = {
    1: {
      A: -7.93954283e-7,
      B: 0.000493457474,
      C: -0.1231642956,
      D: 16.0233664,
      E: 45.59224,
    },
    2: {
      A: -2.55986906e-6,
      B: 0.00116119212,
      C: -0.205352889,
      D: 17.3690866,
      E: 55.4261,
    },
  };

  var gender = sex === "m" || sex === "M" || sex === "male" ? 1 : 2;
  a = params[gender].A;
  b = params[gender].B;
  c = params[gender].C;
  d = params[gender].D;
  e = params[gender].E;

  const value = best;
  bwsum =
    a * Math.pow(bw, 4) +
    b * Math.pow(bw, 3) +
    c * Math.pow(bw, 2) +
    d * bw +
    e;
  var exactResult = (value * 500) / bwsum;
  result = Math.round(exactResult * 1000) / 1000;

  if (!isNaN(result) && result !== Infinity) {
    total += result.toFixed(3);
  }

  var totalResult = Math.round(total * 1000) / 1000;

  return `${totalResult.toFixed(3)}`;
}
